import { Edit } from '@mui/icons-material';
import { Button, Dialog, IconButton, TableProps, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';
import { IPrivateService } from '../types';
import { getLocalizedErrorMessage } from '../utils/functions';

const DESCRIPTION_LIMIT = 200;
const TITLE_LIMIT = 60;
type FormData = {
    'title.he': string;
    'title.en': string;
    'description.he': string;
    'description.en': string;
    duration: number;
    price: number;
    price_before_discount?: number;
    gap_duration?: number;
};
const PrivateServiceItem = (props: { service: IPrivateService; refetch: () => void }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const alert = useContext(AlertContext);
    const form = useForm<FormData>({
        defaultValues: {
            'description.he': props.service.description?.he,
            'description.en': props.service.description?.en,
            duration: props.service.duration,
            gap_duration: props.service.gap_duration,
            price: props.service.price,
            price_before_discount: props.service.price_before_discount,
            'title.en': props.service.title?.en,
            'title.he': props.service.title?.he,
        },
    });
    const handleSave: SubmitHandler<any> = async (data) => {
        try {
            const { title, description, gap_duration, duration } = data;
            await axios.put(`${constants.API_URL}/instructor/admin/private_services`, {
                _id: props.service._id,
                title,
                description,
                gap_duration,
                duration,
            });

            props.refetch();
            alert?.showAlert('השירות עודכן');
            setIsDialogOpen(false);
        } catch (error) {
            if (error instanceof AxiosError) {
                alert?.showAlert(getLocalizedErrorMessage(error, 'he') || 'משהו השתבש', 'error');
            }
            alert?.showAlert('משהו השתבש', 'error');
        }
    };
    return (
        props.service && (
            <>
                <Dialog
                    maxWidth={'md'}
                    fullWidth
                    PaperProps={{ style: { borderRadius: 20 } }}
                    open={isDialogOpen}
                    onClose={() => {
                        setIsDialogOpen(false);
                    }}>
                    <div className='p-8 pt-4' dir='rtl'>
                        <div>
                            <h1 className='text-center font-medium text-lg'>עריכת שירות</h1>
                        </div>
                        <TextField
                            label='כותרת עברית'
                            variant='standard'
                            fullWidth
                            inputProps={{ maxLength: TITLE_LIMIT }}
                            {...form.register('title.he', { required: true })}
                            helperText={`${(form.watch('title.he') as string)?.length || 0}/${TITLE_LIMIT}`}
                        />
                        <TextField
                            label='כותרת אנגלית'
                            variant='standard'
                            fullWidth
                            inputProps={{ maxLength: TITLE_LIMIT }}
                            {...form.register('title.en', { required: true })}
                            helperText={`${(form.watch('title.en') as string)?.length || 0}/${TITLE_LIMIT}`}
                        />
                        <TextField
                            label='תיאור עברית'
                            helperText={`${(form.watch('description.he') as string)?.length || 0}/${DESCRIPTION_LIMIT}`}
                            variant='standard'
                            fullWidth
                            {...form.register('description.he', { maxLength: DESCRIPTION_LIMIT })}
                            multiline
                            inputProps={{ maxLength: DESCRIPTION_LIMIT }}
                        />
                        <TextField
                            label='תיאור אנגלית'
                            helperText={`${(form.watch('description.en') as string)?.length || 0}/${DESCRIPTION_LIMIT}`}
                            variant='standard'
                            fullWidth
                            InputProps={{}}
                            {...form.register('description.en', { maxLength: DESCRIPTION_LIMIT })}
                            multiline
                            inputProps={{ maxLength: DESCRIPTION_LIMIT }}
                        />
                        <div className='flex gap-4 mt-8'>
                            <TextField
                                type='number'
                                label='משך השירות'
                                disabled
                                variant='standard'
                                fullWidth
                                {...form.register('duration', { valueAsNumber: true, disabled: true })}
                                InputProps={{ endAdornment: 'דקות' }}
                            />
                            <TextField
                                type='number'
                                label='הפסקה לאחר מפגש'
                                variant='standard'
                                fullWidth
                                {...form.register('gap_duration', { valueAsNumber: true })}
                                InputProps={{ endAdornment: <span className='text-xs opacity-50'>דקות</span> }}
                            />
                            <TextField
                                type='number'
                                label='מחיר'
                                variant='standard'
                                fullWidth
                                {...(form.register('price'), { required: true, valueAsNumber: true, disabled: true })}
                                InputProps={{ endAdornment: '₪' }}
                            />
                            <TextField
                                type='number'
                                label='מחיר לפני הנחה'
                                variant='standard'
                                fullWidth
                                {...form.register('price_before_discount', { valueAsNumber: true, disabled: true })}
                                InputProps={{ endAdornment: '₪' }}
                            />
                        </div>
                        <div className='mt-8 flex justify-between'>
                            <Button
                                variant='text'
                                color='error'
                                onClick={() => {
                                    form.reset();
                                    setIsDialogOpen(false);
                                }}>
                                ביטול
                            </Button>
                            <Button variant='contained' onClick={form.handleSubmit(handleSave)}>
                                שמירה
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <div className='w-full border-b py-2 px-4 flex flex-row justify-between  hover:bg-gradient-to-l from-gray-100 to-white transition-colors '>
                    <span>
                        <h1 className='font-semibold'>{props.service.title?.he}</h1>
                        <span className='text-sm flex-row gap-4 flex'>
                            <span>{`${props.service.price} ₪`}</span>
                            <span className='border-s border-dotted border-black my-1' />
                            <span>{`${props.service.duration} דקות`}</span>
                            <span className='border-s border-dotted border-black my-1' />
                            <span>{props.service.public ? 'מפגש ללקוחות בתשלום' : 'מפגש למנויי GroupHug'}</span>
                        </span>
                    </span>

                    <span className='flex flex-row gap-2'>
                        <IconButton onClick={() => setIsDialogOpen(true)}>
                            <Edit />
                        </IconButton>
                    </span>
                </div>
            </>
        )
    );
};
const PrivateServicesTable = ({ privateServices = [], ...props }: { privateServices?: IPrivateService[]; refetch: () => void } & Partial<TableProps>) => {
    return (
        <div className='flex flex-col'>
            {privateServices?.map((service, index) => (
                <PrivateServiceItem service={service} key={`private-session-row#${index}`} refetch={props.refetch} />
            ))}
        </div>
    );
};

export default PrivateServicesTable;
