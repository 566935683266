import { Box, Dialog, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';

const VacationDialog = ({
    isOpen,
    onClose,
    setVacationError,
    vacationError,

    onUpdateVacation,
    onClearVacation,
    initVacation,
}: {
    isOpen: boolean;
    onClose: (v: boolean) => void;
    vacationError: string;
    setVacationError: (v: string) => void;
    vacation?: { start?: Date; end?: Date };
    setVacation: Dispatch<SetStateAction<{ start?: Date; end?: Date } | undefined>>;
    onUpdateVacation: (v: { start: Date; end: Date }) => void;
    onClearVacation: () => void;
    initVacation?: { start?: Date; end?: Date };
}) => {
    const [vacationUpdate, setVacationUpdate] = useState<{ start?: Date; end?: Date } | undefined>(initVacation);
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Box minWidth={250} p={2} pt={2}>
                <div className='pb-2'>
                    <Typography variant='h5' textAlign={'center'}>
                        חופשה
                    </Typography>
                </div>
                <div dir='rtl' className='flex flex-row gap-4'>
                    <div>
                        <div className='text-right'>התחלה</div>
                        <TextField
                            type='date'
                            value={vacationUpdate?.start ? moment(vacationUpdate?.start).format('YYYY-MM-DD') : undefined}
                            onChange={(e) => {
                                setVacationError('');
                                setVacationUpdate((prev) => ({ ...prev, start: moment(e.target.value).toDate() }));
                            }}
                        />
                    </div>
                    <div>
                        <div className='text-right'>סיום</div>
                        <TextField
                            type='date'
                            value={vacationUpdate?.end ? moment(vacationUpdate?.end).format('YYYY-MM-DD') : undefined}
                            onChange={(e) => {
                                setVacationError('');
                                setVacationUpdate((prev) => ({ ...prev, end: moment(e.target.value).toDate() }));
                            }}
                        />
                    </div>
                </div>
                <Box display='flex' justifyContent='center'>
                    <Typography variant='caption' textAlign={'center'} color='red'>
                        {vacationError}
                    </Typography>
                </Box>
                <Box justifyContent={'space-between'} display={'flex'} mt={2} gap={2}>
                    <button
                        className='bg-blue-200 px-8 py-2 rounded-3xl hover:scale-105 transition-all font-medium text-blue-800'
                        type='button'
                        onClick={async () => {
                            try {
                                if (!vacationUpdate?.start || !vacationUpdate?.end) throw new Error('חובה להגדיר תאריך התחלה וסיום');
                                await onUpdateVacation({ start: vacationUpdate?.start, end: vacationUpdate?.end });
                                onClose(false);
                            } catch (e) {
                                setVacationError((e as Error).message);
                            }
                        }}>
                        הגדר
                    </button>
                    <button className='text-red-600 font-medium px-4 py-2 rounded-full hover:scale-105 transition-all' type='button' onClick={onClearVacation}>
                        נקה
                    </button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default VacationDialog;
