import { TimeRange, WeeklySchedule } from './WorkingHours';

export type WorkingHoursState = { schedule: WeeklySchedule; updated: boolean };

export type Actions =
    | { type: 'CLEAR_DAY_TIMES'; payload: keyof WeeklySchedule }
    | { type: 'CLEAR_UPDATED' }
    | { type: 'SET_SCHEDULE'; payload: WeeklySchedule }
    | { type: 'ADD_DAY_TIME'; payload: { day: keyof WeeklySchedule; time: TimeRange } }
    | { type: 'REMOVE_DAY_TIME'; payload: { day: keyof WeeklySchedule; _id: string } };

export const workingHoursReducer = (state: WorkingHoursState, action: Actions): WorkingHoursState => {
    switch (action.type) {
        case 'CLEAR_UPDATED':
            return { ...state, updated: false };
        case 'SET_SCHEDULE':
            return { updated: false, schedule: action.payload };
        case 'CLEAR_DAY_TIMES':
            return { ...state, schedule: { ...state.schedule, [action.payload]: [] }, updated: true };
        case 'ADD_DAY_TIME':
            return { ...state, schedule: { ...state.schedule, [action.payload.day]: [...state.schedule[action.payload.day], action.payload.time] }, updated: true };
        case 'REMOVE_DAY_TIME':
            return {
                ...state,
                schedule: { ...state.schedule, [action.payload.day]: state.schedule[action.payload.day].filter((x) => x._id !== action.payload._id) },
                updated: true,
            };
        default:
            return state;
    }
};
