import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import constants from '../../config/constants';
import axios from 'axios';
import { useContext, useState } from 'react';
import { AlertContext } from '../../context/alert.context';

type LoginFormProps = {
    onLoginSuccess: (v: { member_id: string; token: string; refresh_token: string }) => void;
    onError: (error: any) => void;
    startLoading: () => void;
    endLoading: () => void;
};
const LoginForm = ({ onLoginSuccess, onError, endLoading, startLoading }: LoginFormProps) => {
    const alert = useContext(AlertContext);

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleLogin: SubmitHandler<LoginInputs> = async (data) => {
        try {
            startLoading();
            const response = await axios.post(`${constants.API_URL}/user/expert-login`, data);
            if (!response.data.member?.expert_profile?._id) {
                alert?.showAlert('אין לך פרופיל עדיין', 'error');
            } else {
                onLoginSuccess({ member_id: response.data.member._id, token: response.data.token, refresh_token: response.data.member.refresh_token });
            }
        } catch (error) {
            onError(error);
        } finally {
            endLoading();
        }
    };
    type LoginInputs = { email: string; password: string };
    const { control, handleSubmit } = useForm<LoginInputs>();
    return (
        <div className='mt-2 gap-6 flex flex-col w-full'>
            <Controller
                control={control}
                name='email'
                render={({ field }) => <TextField required fullWidth label='כתובת מייל' id='email' autoComplete='email' variant='standard' autoFocus {...field} />}
            />
            <Controller
                control={control}
                name='password'
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        label='סיסמה'
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        variant='standard'
                        autoComplete='current-password'
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={toggleShowPassword}>
                                    {showPassword ? <VisibilityOff style={{ fontSize: 16 }} /> : <Visibility style={{ fontSize: 16 }} />}
                                </IconButton>
                            ),
                        }}
                        {...field}
                    />
                )}
            />
            <Button
                onClick={handleSubmit(handleLogin)}
                type='submit'
                variant='contained'
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    alignSelf: 'end',
                    paddingInline: 40,
                }}>
                התחברות
            </Button>
        </div>
    );
};

export default LoginForm;
