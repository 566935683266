import { Star } from '@mui/icons-material';
import { CircularProgress, Rating, Table, TableBody, TableCell, TablePagination, TableRow } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import constants from '../../config/constants';
import useFetch from '../../hooks/useFetch';

const Reviews = () => {
    const { data, loading } = useFetch<{ reviews: any[]; avg: null | number }>(`${constants.API_URL}/instructor/admin/review`);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    return (
        <div>
            {loading ? (
                <div className='text-center mt-10'>
                    <CircularProgress size={16} />
                </div>
            ) : data?.reviews.length === 0 ? (
                <div className='text-center mt-12 text-gray-500'>
                    <h2>עדיין אין משובים כאן</h2>
                </div>
            ) : (
                <div style={{ paddingInline: 24 }}>
                    {data?.avg ? (
                        <div className='text-center text-xl'>
                            <div dir='rtl'>ממוצע דירוג</div>
                            <div dir='rtl'>{data?.avg?.toFixed(2)}</div>
                            <Rating value={data?.avg ?? 0} precision={0.5} size='large' readOnly />
                        </div>
                    ) : null}
                    <Table style={{ direction: 'rtl' }}>
                        <TableBody>
                            {data?.reviews.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((review: any) => (
                                <TableRow key={`review#${review._id}`}>
                                    <TableCell>{review.createdAt ? moment(review.createdAt).format('DD/MM/YY') : ''}</TableCell>
                                    <TableCell>{review.text}</TableCell>
                                    <TableCell style={{ width: 250 }}>
                                        <span style={{ fontSize: 12 }}>{review.__t === 'Review:PrivateSession' ? 'פרטני' : review.meeting_title}</span>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ textAlign: 'center' }}>{review.rating}</div>
                                        <div style={{ flexDirection: 'row', display: 'flex', gap: 2, justifyContent: 'center' }}>
                                            {Array.from(Array(review.rating), (_, i) => (
                                                <Star style={{ color: 'gold', fontSize: 10 }} />
                                            ))}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div dir='rtl'>
                        <TablePagination
                            className='flex gap-4 justify-end'
                            labelRowsPerPage='מספר משובים בעמוד:'
                            backIconButtonProps={{ style: { transform: 'rotate(180deg)' } }}
                            nextIconButtonProps={{ style: { transform: 'rotate(180deg)' } }}
                            component='div'
                            count={data?.reviews.length ?? 0}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Reviews;
