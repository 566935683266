import { Done, Error } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material';
import { useContext } from 'react';
import { AlertContext } from '../context/alert.context';

const AlertComp = () => {
    const alert = useContext(AlertContext);
    return (
        <Snackbar open={alert?.open}>
            <Alert icon={alert?.type === 'success' ? <Done /> : <Error />} color={alert?.type}>
                {alert?.message}
            </Alert>
        </Snackbar>
    );
};

export default AlertComp;
