import { Star } from '@mui/icons-material';
import moment from 'moment';

const MeetingsTable = (props: { showZoomInfo?: boolean; rows: any[]; loading: boolean; showRating?: boolean }) => {
    return (
        <table className=' w-full border-separate'>
            <thead className='text-start'>
                <th className='text-start'>תאריך</th>
                <th className='text-start'>כותרת</th>
                <th className='text-center'>רשומים</th>
                {props.showRating ? <th className='text-center'>דירוג</th> : null}
                {props.showZoomInfo ? (
                    <>
                        <th className='text-start'>קישור לזום</th>
                        <th className='text-start'>סיסמה</th>
                    </>
                ) : null}
            </thead>
            {props.rows
                ?.slice()
                .reverse()
                .map((row) => (
                    <tr className='border-b py-1 border px-4'>
                        <td className='border-b py-1'>{moment(row.time).format('DD/MM/YY H:mm')}</td>
                        <td className='border-b py-1'>{row.title}</td>
                        <td className='text-center border-b py-1'>{row.registered}</td>
                        {props.showRating ? (
                            <td className='text-center border-b py-1'>
                                {row?.rating?.avg && (
                                    <>
                                        <span className='flex flex-row justify-center items-center'>
                                            <span>{row.rating?.avg?.toFixed(1)}</span>
                                            <Star className='text-yellow-500' />
                                        </span>
                                        <span className='text-sm'>{`(${row.rating?.count} מדרגים)`}</span>
                                    </>
                                )}
                            </td>
                        ) : null}
                        {props.showZoomInfo ? (
                            <>
                                <td className='border-b py-1'>{row.zoom}</td>
                                <td className='border-b py-1'>{row.zoom_password}</td>
                            </>
                        ) : null}
                    </tr>
                ))}
        </table>
    );
};

export default MeetingsTable;
