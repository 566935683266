import { Card, CardHeader } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import constants from '../../config/constants';
import useFetch from '../../hooks/useFetch';
import { IService } from '../../types';
import MeetingServicesTable from '../MeetingServicesTable';
import MeetingsTable from '../MeetingsTable';

const GroupSessionsTab = () => {
    const meetings = useFetch<any[]>(`${constants.API_URL}/instructor/admin/meetings`);
    const { data: services } = useFetch<IService[]>(`${constants.API_URL}/instructor/admin/services`);
    const [reviewsAvg, setReviewsAvg] = useState<{ [key: string]: { avg: number; count: number } }>({});
    useEffect(() => {
        const fetchReviews = async () => {
            const reviews = await axios.get<Record<string, { avg: number; count: number }>>(`${constants.API_URL}/instructor/admin/meeting/reviews`, {
                params: { ids: meetings.data?.map((x) => x._id) ?? [] },
            });

            setReviewsAvg(reviews.data);
        };
        if (meetings.data?.length) fetchReviews();
    }, [meetings.data]);
    return (
        <div>
            <Card dir='rtl' style={{ marginTop: 20 }}>
                <CardHeader title='הרצאות וסדנאות עתידיות' />
                <MeetingsTable showZoomInfo loading={meetings.loading} rows={meetings.data?.filter((x) => moment(x.time).isAfter(moment())) || []} />
            </Card>
            <Card dir='rtl' style={{ marginTop: 20 }}>
                <CardHeader title='הרצאות וסדנאות עבר' />
                <MeetingsTable
                    showRating
                    loading={meetings.loading}
                    rows={(meetings.data?.filter((x) => moment(x.time).isSameOrBefore(moment())) || []).map((meeting) => ({
                        ...meeting,
                        rating: reviewsAvg[meeting._id] ? reviewsAvg[meeting._id] : null,
                    }))}
                />
            </Card>
            <Card dir='rtl' style={{ marginTop: 20 }}>
                <CardHeader title='שירותים קבוצתיים' />
                <MeetingServicesTable services={services} />
            </Card>
        </div>
    );
};

export default GroupSessionsTab;
