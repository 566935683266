import moment from 'moment';

type PaymentsState = {
    helpingHand: { count: number; price: number };
    consultationSessions: { count: number; price: number; info?: string };
    oneToOneSessions: {
        count: number;
        price: number;
    };
    groupSessions: {
        count: number;
        price: number;
        info?: string;
    };
    safe_space: {
        count: number;
        price: number;
        info?: string;
    };
    total: { price: number };
    updatedAt: Date;
};
type State = {
    isError?: boolean;
    loading: boolean;
    date: { month: number; year: number };
    payments?: PaymentsState;
};
type Action =
    | { type: 'START_LOADING' }
    | { type: 'FINISH_LOADING' }
    | { type: 'SET_IS_ERROR' }
    | { type: 'CLEAR_ERROR' }
    | { type: 'SET_PAYMENTS'; payload: PaymentsState }
    | { type: 'NEXT_MONTH' }
    | { type: 'SET_DATE'; padyload: Date }
    | { type: 'PREV_MONTH' };
export const initialState: State = { loading: false, date: { month: moment().month(), year: moment().year() } };

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, loading: true };
        case 'FINISH_LOADING':
            return { ...state, loading: false };
        case 'SET_IS_ERROR':
            return { ...state, isError: true };
        case 'CLEAR_ERROR':
            return { ...state, isError: false };
        case 'SET_PAYMENTS':
            return { ...state, payments: action.payload };
        case 'NEXT_MONTH':
            return {
                ...state,
                date: {
                    month: (state.date.month + 1) % 12,
                    year: state?.date.month === 11 ? state.date.year + 1 : state.date.year,
                },
            };
        case 'PREV_MONTH':
            return {
                ...state,
                date: {
                    month: state?.date.month === 0 ? 11 : (state.date.month - 1) % 12,
                    year: state?.date.month === 0 ? state.date.year - 1 : state.date.year,
                },
            };
        case 'SET_DATE': {
            const m = moment(action.padyload);

            return {
                ...state,
                date: { month: m.month(), year: m.year() },
            };
        }
        default:
            return state;
    }
};
