import { Button, CircularProgress, Dialog } from '@mui/material';
import { addMinutes } from 'date-fns';
import he from 'date-fns/locale/he';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import { Controller, useForm } from 'react-hook-form';

registerLocale('he', he);
type AppointmentScheduleDialogProps = {
    title: string;
    duration?: number;
    onScheduleAppointmentButtonPressed: (selectedTime: Date, isFollowup: boolean, counpon?: string) => void;
    service?: any;
    open: boolean;
    onClose: () => void;
    loading?: boolean;
};

type Inputs = { datetime: Date | null; duration: number; coupon: string };
const AppointmentScheduleDialog = (props: AppointmentScheduleDialogProps) => {
    // const [datetime, setDatetime] = useState<Date | null>(null);
    // const [duration, setDuration] = useState<number>(props.duration || 20);
    const formSubmitHandler = (data: Inputs) => {
        if (data.datetime) props.onScheduleAppointmentButtonPressed(data.datetime, false, data.coupon);
    };
    const { register, control, formState, handleSubmit } = useForm<Inputs>({ defaultValues: { datetime: null, duration: props.duration || 20, coupon: '' } });
    return (
        <Dialog open={props.open} onClose={props.onClose} className='overflow-visible' PaperProps={{ style: { borderRadius: 10 } }}>
            <div dir='rtl' className=' py-4 h-[450px] w-[450px] text-center flex flex-col gap-4 overflow-visible'>
                <div>
                    <h2 className='text-lg font-bold'>{props.title}</h2>
                    <h3>{props.service?.title.he}</h3>
                </div>
                <div className='flex flex-col justify-between h-full'>
                    <div className='text-center py-1 px-8 flex flex-col justify-between gap-6'>
                        <div className='flex flex-row gap-4 items-center flex-1'>
                            <span className='font-medium w-40 text-start'>משך המפגש</span>
                            <span className='flex items-center gap-4 w-full'>
                                <input
                                    type='number'
                                    step={5}
                                    style={{ width: '100%', borderRadius: 10, borderWidth: 1, padding: 4, textAlign: 'center', fontFamily: 'Open Sans' }}
                                    min={10}
                                    max={120}
                                    {...register('duration')}
                                />
                                <span className='px-1 flex flex-1 w-full'>{'דקות'}</span>
                            </span>
                        </div>
                        <div className='flex flex-row gap-4 items-center'>
                            <label className='font-medium text-start w-40'>מועד המפגש</label>
                            <Controller
                                control={control}
                                name='datetime'
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker
                                        popperProps={{
                                            dir: 'rtl',
                                            positionFixed: true, // use this to make the popper position: fixed
                                            strategy: 'fixed',
                                        }}
                                        timeCaption='שעה'
                                        timeIntervals={5}
                                        wrapperClassName='w-full'
                                        customInput={
                                            <input
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    borderWidth: 1,
                                                    borderRadius: 10,
                                                    paddingBlock: 4,
                                                    fontFamily: 'Open Sans',
                                                }}
                                            />
                                        }
                                        dateFormat={'dd/MM/yy HH:mm'}
                                        timeFormat='HH:mm'
                                        locale={'he'}
                                        showTimeSelect
                                        selected={field.value}
                                        onChange={field.onChange}
                                        minDate={addMinutes(new Date(), 20)}
                                    />
                                )}
                            />
                        </div>
                        <div className='flex flex-row gap-4 items-center'>
                            <label className='font-medium text-start w-40'>קופון</label>
                            <span style={{ borderRadius: 10, borderWidth: 1, padding: 4, textAlign: 'center', fontFamily: 'Open Sans', width: '100%' }}>
                                {' '}
                                <input {...register('coupon')} type='text' placeholder='קוד קופון' />
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-2 px-1'>
                        <Button variant='contained' disabled={!formState.isValid || props.loading} onClick={handleSubmit(formSubmitHandler)}>
                            קביעת פגישה
                        </Button>
                        <span className='text-xs leading-snug font-base whitespace-pre-wrap'>
                            {'במידה והמפגש בתשלום - יישלח למשתמש קישור תשלום הזמין ל-24 שעות.\nכאשר התשלום יבוצע המפגש ייקבע.\nמפגש ללא עלות - נקבע מיידית'}
                        </span>
                        {props.loading && <CircularProgress />}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AppointmentScheduleDialog;
