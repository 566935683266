import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';

export default function ResetPassword() {
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();
    const alert = useContext(AlertContext);
    const [email, setEmail] = useState<string>('');
    const handleSubmit = async () => {
        try {
            await axios.post(`${constants.API_URL}/member/forgot_password`, { email });
            setIsSuccess(true);
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error: any) {
            if (error?.response?.data?.errors) alert?.showAlert(error.response.data.errors.map((error: any) => error['he']).join('\n'), 'error');
            else alert?.showAlert(error.message, 'error');
        }
    };

    return (
        <>
            <Container dir='rtl' component='main' maxWidth='xs'>
                <div className='flex flex-col items-center mt-16'>
                    <Avatar sx={{ m: 1, backgroundColor: 'secondary.main' }} src={require('../assets/grouphug_1024.png')} />

                    <h5>איפוס סיסמה</h5>

                    <div className='flex flex-col w-full my-4 items-center'>
                        {isSuccess ? (
                            <div className='text-center flex items-center flex-col gap-4'>
                                <div className='flex flex-col gap-1'>
                                    <h4 className='text-center'>שלחנו לך אימייל לאיפוס הסיסמה</h4>
                                    <h4 className='text-center'>מיד נעביר אותך למסך ההתחברות</h4>
                                </div>
                                <CircularProgress size={16} />
                            </div>
                        ) : (
                            <div className='items-center flex flex-col w-full'>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='email'
                                    label='כתובת מייל'
                                    name='email'
                                    autoComplete='email'
                                    autoFocus
                                    variant='standard'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button onClick={handleSubmit} variant='contained' style={{ margin: 12 }}>
                                    איפוס
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </>
    );
}
