import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useContext } from 'react';

import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import constants from '../config/constants';
import { AlertContext } from '../context/alert.context';
import { IExpert, ProfileData } from '../types';
import { getLocalizedErrorMessage } from '../utils/functions';

const ExpertProfile = ({ profileData }: { profileData?: IExpert }) => {
    const { handleSubmit, control, formState } = useForm<ProfileData>({ defaultValues: profileData });

    const alert = useContext(AlertContext);
    const updateProfile: SubmitHandler<ProfileData> = async (data) => {
        try {
            await axios.put(`${constants.API_URL}/instructor/admin/profile`, data);
            alert?.showAlert('הפרופיל עודכן בהצלחה!', 'success');
        } catch (error: any) {
            console.log('error', error);
            console.log('error instanceof AxiosError', error instanceof AxiosError);
            if (error instanceof AxiosError) {
                alert?.showAlert(getLocalizedErrorMessage(error, 'he') || 'משהו השתבש', 'error');
            } else alert?.showAlert('משהו השתבש', 'error');
        }
    };
    return (
        <div>
            <Table sx={{ direction: 'rtl', textAlign: 'right' }}>
                <TableHead>
                    <TableRow>
                        <TableCell width={100} />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody style={{ textAlign: 'right' }}>
                    <TableRow style={{ direction: 'rtl', textAlign: 'right' }}>
                        <TableCell>שם</TableCell>

                        <TableCell>
                            <Controller name='name' control={control} render={({ field }) => <TextField {...field} placeholder='שם' disabled />} />
                        </TableCell>
                        <TableCell>
                            <Controller
                                name='english_name'
                                control={control}
                                render={({ field }) => <TextField {...field} placeholder='שם באנגלית' inputProps={{ dir: 'ltr' }} />}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>חייב במע"מ</TableCell>
                        <TableCell>
                            <Controller
                                control={control}
                                name='licensed_dealer'
                                render={({ field }) => (
                                    <FormControl>
                                        <RadioGroup row aria-labelledby='demo-controlled-radio-buttons-group' {...field}>
                                            <FormControlLabel value={true} control={<Radio />} label='עוסק מורשה' />
                                            <FormControlLabel value={false} control={<Radio />} label='עוסק פטור' />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>יצירת קשר</TableCell>
                        <TableCell>
                            <Controller name='email' control={control} render={({ field }) => <TextField {...field} placeholder='מייל' disabled fullWidth />} />
                        </TableCell>

                        <TableCell>
                            <Controller
                                name='phone'
                                control={control}
                                rules={{ required: true }}
                                render={({ field, formState }) => <TextField {...field} error={Boolean(formState.errors[field.name])} placeholder='טלפון' />}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell rowSpan={2}>טייטל</TableCell>
                        <TableCell colSpan={2}>
                            <Controller
                                rules={{ required: true }}
                                name='title'
                                control={control}
                                // rules={{ maxLength: 60 }}
                                render={({ field }) => <TextField {...field} placeholder='טייטל' fullWidth helperText={`${field.value?.length ?? 0}/60`} />}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Controller
                                name='english_title'
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder='טייטל באנגלית'
                                        fullWidth
                                        helperText={`${field.value?.length ?? 0}/60`}
                                        inputProps={{ style: { textAlign: 'left', direction: 'ltr' } }}
                                    />
                                )}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell rowSpan={2}>תיאור</TableCell>
                        <TableCell colSpan={2}>
                            <Controller
                                name='description_lng.he'
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TextField {...field} placeholder='תיאור' fullWidth multiline helperText={`${field.value?.length ?? 0}/1200`} />}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Controller
                                name='description_lng.en'
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder='תיאור באנגלית'
                                        inputProps={{ style: { textAlign: 'left', direction: 'ltr' } }}
                                        fullWidth
                                        multiline
                                        helperText={`${field.value?.length ?? 0}/1200`}
                                    />
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>זום</TableCell>
                        <TableCell>
                            <Controller name='zoom' control={control} render={({ field }) => <TextField {...field} placeholder='קישור קבוע' fullWidth type='url' />} />
                        </TableCell>

                        <TableCell>
                            <Controller name='zoom_password' control={control} render={({ field }) => <TextField {...field} placeholder='סיסמה' />} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Box>
                                <Typography variant='caption'>{`עודכן לאחרונה: ${moment(profileData?.updatedAt).format('DD/MM/YY HH:mm')}`}</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            <Box p={2} textAlign={'end'}>
                <Button variant='contained' disabled={!formState.isDirty} onClick={handleSubmit(updateProfile)}>
                    עדכון
                </Button>
            </Box>
            <Box py={3}>
                <Typography textAlign='center' fontSize={14}>
                    {'במידה ויש צורך בשינוי פרטים נוספים - אנא פנו אלינו למייל'}
                    <Link href='mailto:contact@grouphug.online'>contact@grouphug.online</Link>
                </Typography>
            </Box>
        </div>
    );
};

export default ExpertProfile;
