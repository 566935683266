'use client';

import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode, createContext, useContext, useState } from 'react';

type ModalProps = {
    show: (v: { title: string; body?: ReactNode; footer?: ReactNode; isClosable?: boolean }) => void;
    hide: () => void;
};

const Modal = createContext<ModalProps>({
    show: () => {},
    hide: () => {},
});

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [title, setTitle] = useState<string>('');
    const [body, setBody] = useState<ReactNode>('');
    const [footer, setFooter] = useState<ReactNode>('');
    const [visible, setVisible] = useState<boolean>(false);
    const [isClosable, setIsClosable] = useState<boolean>(true);

    return (
        <Modal.Provider
            value={{
                hide: () => {
                    setVisible(false);
                },
                show: ({ title, body, footer, isClosable }) => {
                    setTitle(title);
                    setFooter(footer);
                    setBody(body);
                    setVisible(true);
                    setIsClosable(isClosable ?? true);
                },
            }}>
            <>
                <Dialog
                    TransitionComponent={Transition}
                    PaperProps={{ style: { borderRadius: 20 } }}
                    open={visible}
                    onClose={() => {
                        if (isClosable) setVisible(false);
                    }}>
                    <DialogTitle className={`text-2xl font-semibold`}>
                        <div className='flex justify-between items-center'>
                            {title}
                            {isClosable && (
                                <IconButton
                                    size='small'
                                    onClick={() => {
                                        setVisible(false);
                                    }}>
                                    <Close />
                                </IconButton>
                            )}
                        </div>
                    </DialogTitle>
                    <DialogContent className='bg-gray'>
                        <div className='p-2 whitespace-pre-line' style={{}}>
                            {body}
                        </div>
                    </DialogContent>
                    <DialogActions>{footer}</DialogActions>
                </Dialog>
                {children}
            </>
        </Modal.Provider>
    );
};

export const useModal = () => {
    const modal = useContext(Modal);

    const show = ({ title, body, footer, isClosable }: { title: string; body?: ReactNode; footer?: ReactNode; isClosable?: boolean }) => {
        modal.show({ title, body, footer, isClosable });
    };
    const hide = () => {
        modal.hide();
    };
    return { show, hide };
};
