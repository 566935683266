import { useState } from 'react';

const TimeInput = ({ onChange }: { value?: number; onChange?: (v: number) => void }) => {
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const onMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = parseInt(e.target.value);
        if (Number.isNaN(v)) setMinutes(0);
        else {
            if (v >= 5 && v < 60) {
                setMinutes(v);
                onChange?.(hours * 100 + v);
                (e.target.nextElementSibling as HTMLInputElement)?.focus();
            }
            if (v > 59) {
                setHours(59);
            } else {
                setMinutes(v);
                onChange?.(hours * 100 + v);
            }
        }
    };
    const onHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = parseInt(e.target.value);
        if (Number.isNaN(v)) setHours(0);
        else {
            if (v >= 3 && v < 23) {
                setHours(v);
                onChange?.(v * 100 + minutes);
                (e.target.nextElementSibling as HTMLInputElement)?.focus();
            }
            if (v > 23) {
                setHours(23);
            } else {
                setHours(v);
                onChange?.(v * 100 + minutes);
            }
        }
    };
    return (
        <div className='flex flex-row gap-1' dir='ltr'>
            <input className='border-b flex flex-1 w-16 px-2 py-1 order-1' maxLength={2} max={23} placeholder='HH' type='text' onChange={onHourChange} value={hours} />
            <input className='border-b flex flex-1 w-16 px-2 py-1 order-2' maxLength={2} max={59} placeholder='MM' type='text' onChange={onMinuteChange} value={minutes} />
        </div>
    );
};

export default TimeInput;
