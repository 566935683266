import { Button, Stack, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useContext, useEffect, useReducer, useState } from 'react';
import { IExpert } from '../../types';

import { ArrowBack, Close } from '@mui/icons-material';
import constants from '../../config/constants';
import { AlertContext } from '../../context/alert.context';
import { useModal } from '../../context/ModalProvider';
import useFetch from '../../hooks/useFetch';
import TimeInput from '../TimeInput';
import VacationDialog from './VacationDialog';
import { workingHoursReducer, WorkingHoursState } from './WorkingHoursReducer';

export type TimeRange = { from: number; to: number; _id: string };
const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const hebrewDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
export type WeeklySchedule = {
    monday: TimeRange[];
    tuesday: TimeRange[];
    wednesday: TimeRange[];
    thursday: TimeRange[];
    friday: TimeRange[];
    saturday: TimeRange[];
    sunday: TimeRange[];
};
const initialState: WorkingHoursState = {
    schedule: { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
    updated: false,
};

const WorkingHours = ({ profileData }: { profileData?: IExpert }) => {
    const [state, dispatch] = useReducer(workingHoursReducer, initialState);
    const scheduleData = useFetch<WeeklySchedule>(`${constants.API_URL}/instructor/admin/schedule`);
    const alert = useContext(AlertContext);

    useEffect(() => {
        if (scheduleData.data) {
            dispatch({ type: 'SET_SCHEDULE', payload: scheduleData.data });
        }
    }, [scheduleData.data]);
    useEffect(() => {
        const updateSchedule = async () => {
            axios.put(`${constants.API_URL}/instructor/admin/schedule`, { schedule: state.schedule });
            alert?.showAlert('שעות הפעילות עודכנו');
        };
        if (state.updated) {
            updateSchedule();
            dispatch({ type: 'CLEAR_UPDATED' });
        }
    }, [state, alert]);
    const [vacationError, setVacationError] = useState<string>('');
    const [showVacationDialog, setShowVacationDialog] = useState(false);
    const onClearVacation = async () => {
        setVacation(undefined);
        setShowVacationDialog(false);
        await axios.delete(`${constants.API_URL}/instructor/admin/vacation`);
        alert?.showAlert('חופשה נמחקה בהצלחה');
    };
    const [vacation, setVacation] = useState<{ start?: Date; end?: Date } | undefined>(profileData?.vacation);
    const onUpdateVacation = async ({ start, end }: { start: Date; end: Date }) => {
        setVacation({ start, end });
        if (!start || !end) throw new Error('חובה להגדיר תאריך התחלה וסיום');
        if (start > end) {
            throw new Error('סיום החופשה חייב להיות לאחר תחילת החופשה');
        } else {
            await axios.post(`${constants.API_URL}/instructor/admin/vacation`, { start, end });
            alert?.showAlert('חופשה נקבעה בהצלחה');
        }
    };

    const modal = useModal();

    const formatTime = (time: number) => `${String(Math.floor(time / 100)).padStart(2, '0')}:${String(time % 100).padStart(2, '0')}`;
    return (
        <>
            <VacationDialog
                onClearVacation={onClearVacation}
                onUpdateVacation={onUpdateVacation}
                vacationError={vacationError}
                setVacationError={setVacationError}
                isOpen={showVacationDialog}
                onClose={() => setShowVacationDialog(false)}
                initVacation={profileData?.vacation}
                vacation={vacation}
                setVacation={setVacation}
            />
            <div className='p-2'>
                <div className='flex flex-col gap-2 mb-8'>
                    {days.map((day, dayIndex) => {
                        return (
                            <div>
                                <Typography variant='h6'>{hebrewDays[dayIndex]}</Typography>
                                <div className='flex gap-2 flex-row flex-wrap items-center'>
                                    {state.schedule[day.toLowerCase() as keyof WeeklySchedule]?.map((time) => {
                                        return (
                                            <span className='flex gap-4 py-2 pe-2 ps-4 border rounded-full justify-between'>
                                                <span className='flex flex-1 items-center gap-2'>
                                                    <span>{formatTime(time.from)}</span>
                                                    <span className='text-[10px]'>
                                                        <ArrowBack fontSize='small' />
                                                    </span>
                                                    <span>{formatTime(time.to)}</span>
                                                </span>
                                                <button
                                                    type='button'
                                                    className='hover:text-black text-gray-400 transition-all'
                                                    onClick={() => {
                                                        dispatch({ type: 'REMOVE_DAY_TIME', payload: { day: day.toLowerCase() as keyof WeeklySchedule, _id: time._id } });
                                                    }}>
                                                    <Close />
                                                </button>
                                            </span>
                                        );
                                    })}
                                    <button
                                        className='border rounded-full aspect-square w-8 h-8 border-blue-400 hover:bg-blue-400 hover:text-white transition-colors'
                                        type='button'
                                        onClick={() => {
                                            let localStartDate: null | number = null;
                                            let localEndDate: null | number = null;

                                            modal.show({
                                                title: `הוספת שעות פעילות - יום ${hebrewDays[dayIndex]}`,
                                                body: (
                                                    <div dir='ltr' className='flex items-center gap-4 justify-center flex-col  '>
                                                        <div className='text-right'>
                                                            <h5>התחלה</h5>
                                                            <TimeInput
                                                                key={'start'}
                                                                onChange={(v) => {
                                                                    console.log('Start time', v);
                                                                    localStartDate = v; // Update the local variable
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='text-right'>
                                                            <h5>סיום</h5>
                                                            <TimeInput
                                                                key={'end'}
                                                                onChange={(v) => {
                                                                    console.log('End time', v);
                                                                    localEndDate = v; // Update the local variable
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                                footer: (
                                                    <Button
                                                        onClick={() => {
                                                            if (localStartDate && localEndDate && localStartDate < localEndDate) {
                                                                dispatch({
                                                                    type: 'ADD_DAY_TIME',
                                                                    payload: {
                                                                        day: days[dayIndex] as keyof WeeklySchedule,
                                                                        time: { from: localStartDate, to: localEndDate, _id: Math.floor(Math.random() * 99999).toString() },
                                                                    },
                                                                });
                                                                modal.hide();
                                                            }
                                                        }}>
                                                        ADD
                                                    </Button>
                                                ),
                                            });
                                        }}>
                                        +
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <Stack direction='row' gap={2} alignItems={'center'} my={3}>
                    <button className='bg-blue-200 px-4 py-1 rounded-full hover:scale-105 transition-all' type='button' onClick={() => setShowVacationDialog(true)}>
                        הגדרת חופשה
                    </button>

                    {vacation && (
                        <Typography variant='body2' textAlign='center' whiteSpace={'pre-wrap'}>{`חופשה מוגדרת:\tהתחלה: ${moment(vacation.start).format(
                            'DD/MM/YY HH:mm'
                        )}\t\t\tסיום: ${moment(vacation.end).format('DD/MM/YY HH:mm')}`}</Typography>
                    )}
                </Stack>
            </div>
        </>
    );
};

export default WorkingHours;
