import axios from 'axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import constants from '../../config/constants';
import { useState } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type RegisterFormProps = {
    onRegisterSuccess: (email: string) => void;
    onError: (error: any) => void;
    startLoading: () => void;
    endLoading: () => void;
};

const RegisterForm = ({ onRegisterSuccess, endLoading, startLoading, onError }: RegisterFormProps) => {
    const handleRegister: SubmitHandler<RegisterInputs> = async (data) => {
        try {
            startLoading();
            const response = await axios.post(`${constants.API_URL}/user/expert-register`, data);

            onRegisterSuccess(response.data.member.email);
            endLoading();
        } catch (error) {
            console.log('catch');
            onError(error);
        } finally {
            endLoading();
        }
    };
    type RegisterInputs = { email: string; password: string; phone: string; first_name: string; last_name: string };
    const { handleSubmit, control } = useForm<RegisterInputs>();
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };
    return (
        <div className='flex flex-col w-full gap-6'>
            <Controller
                control={control}
                name='email'
                render={({ field }) => <TextField required fullWidth label='כתובת מייל' id='email' {...field} variant='standard' autoFocus />}
            />
            <Controller
                control={control}
                name='first_name'
                render={({ field }) => (
                    <TextField style={{ textAlign: 'right' }} required fullWidth id='first_name' label='שם פרטי' variant='standard' autoComplete='first_name' {...field} />
                )}
            />
            <Controller control={control} name='last_name' render={({ field }) => <TextField required fullWidth id='last_name' label='שם משפחה' variant='standard' {...field} />} />
            <Controller control={control} name='phone' render={({ field }) => <TextField required fullWidth id='phone' label='טלפון' variant='standard' {...field} />} />
            <Controller
                control={control}
                name='password'
                render={({ field }) => (
                    <TextField
                        style={{ textAlign: 'right' }}
                        required
                        fullWidth
                        label='סיסמה'
                        helperText='חייבת להכיל אותיות ומספרים, לפחות 6 תווים'
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        autoComplete='current-password'
                        variant='standard'
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={toggleShowPassword}>
                                    {showPassword ? <VisibilityOff style={{ fontSize: 16 }} /> : <Visibility style={{ fontSize: 16 }} />}
                                </IconButton>
                            ),
                        }}
                        {...field}
                    />
                )}
            />
            <Button
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                    alignSelf: 'center',
                    paddingInline: 40,
                }}
                onClick={handleSubmit(handleRegister)}
                variant='contained'>
                הרשמה
            </Button>
        </div>
    );
};

export default RegisterForm;
